#object-creation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;

  .stepper-bar {
    height: 64px;
    position: relative;
    background-color: var(--color-blue-900);

    .return-wrapper {
      position: absolute;
      width: 250px;
      left: 20px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .MuiButton-root {
        border: none;
        text-transform: none;
        color: white;
      }
    }

    .stepper-wrapper {
      width: 40%;
      height: 100%;
      margin: 0 30%;

      .MuiStepper-root {
        height: 100%;

        .MuiSvgIcon-root {
          color: white;
          opacity: 0.2;
          &.Mui-active {
            opacity: 1;
          }
        }
        .MuiStepIcon-text {
          fill: black;
        }
      }
    }

    .close-wrapper {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 20px;
      height: 64px;
      width: 64px;
      top: 0;
      
      .close-icon {
        color: white;
      }
    }
  }

  .colored-title {
    background-color: var(--color-blue-900);
  }

  .blank-footer {
    flex: 2;
  }

  h1 {
    color: white;
    font-size: 36px;
    font-weight: 900;
  }

  h3 {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-light-grey);
    text-transform: none;
  }
  
  .MuiInputLabel-root {
    color: white;
    font-family: Avenir;
  }

  .MuiInput-root {
    margin-top: 1.5rem;
    background-color: white;
    color: black;
    border-radius: 4px;
    padding: 0 1rem;
    height: 56px;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 400;
  }
}