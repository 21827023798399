#subnet-modal {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  .MuiPaper-root {
    height: 420px;
    width: 450px;
    padding: 1.5rem;
  }
  
  h1 {
    font-family: Avenir;
    font-size: 20px;
    font-weight: 500;
  }
}