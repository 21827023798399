.linearisation {
  .colored-title {
    height: 78px;
  }

  .title-wrapper {
    color: white;
  }

  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;

    .colored-title {
      background-color: var(--color-purple-500);
    }
  }
}