#map-feedback-snackbar {
  .MuiAlert-filledError {
    background-color: var(--color-red-500);
  }
  
  .MuiAlert-filledWarning {
    background-color: var(--color-warning-900);
  }

  .MuiAlert-filledSuccess {
    background-color: var(--color-green-900);
  }
  
  .MuiAlert-filledSuccess {
    background-color: var(--color-blue-500);
  }
}