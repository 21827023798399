.main-button {
  >button {
    border-radius: 23px;
    text-transform: none;
    width: 100%;
    background-color: #404040;
  }

  &.loading {
    >button {
      background: #404040;
      opacity: 0.2;
      color: rgba(248, 248, 248, 1);
    }
  }
}