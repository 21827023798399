.text-field {
  margin-bottom: 2rem;

  .input {
    margin-top: 1.25rem;
    border-radius: 4px;
    border: 1px solid var(--color-light-grey);
    height: 56px;
    font-family: Avenir;
    padding: 16px;

    &.Mui-focus {
      border-color: var(--color-near-black);
    }
  }

  &.no-label {
    margin: 0 !important;
    .input {
      margin-top: 0;
    }
  }

  .helper {
    .helper-icon {
      height: 10px;
      width: 10px;
      color: var(--color-red-500);
    }

    .text-wrapper {
      padding-top: 1px;
    }
  }

  .Mui-error {
    border-color: var(--color-red-500) !important;
    color: var(--color-red-500) !important;
  }

  .Mui-focus {
    color: var(--color-black) !important;
  }
}
