.electrical-element-group-details {
  position: relative;

  .capture-overlay {
    position: absolute;
    z-index: 1000;
    height: 100%;
    width: 100%;
    background-color: white;
    opacity: 0.6;
    top: 0;
  }

  .capture-button, .capture-button button {
    z-index: 2000;
  }
}