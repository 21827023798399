#tis-list {
  margin-top: .5rem;
  padding: 0 1.5rem;

  .deleted {
    background-color: var(--color-red-500);
  }

  .tis-name {
    flex: 1;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 800;
  }

  .MuiChip-root {
    color: white;
    height: 20px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 10px;
    
    &.deleted {
      background-color: var(--color-red-500);
    }
  }

  .MuiTimeline-root {
    padding: 0;
  }

  .MuiTimelineContent-root {
    padding-right: 2px;
    padding-left: 25px;
  }

  .MuiTimelineItem-root {
    &::before {
      content: none;
    }
  }

  .MuiTimelineDot-root {
    position: absolute;
    margin: 0;
    top: 32px;
    left: 0.5px;
    background-color: var(--color-blue-500);
    border-width: 1px;
    box-shadow: none;
  }
  
  .MuiTimelineConnector-root {
    position: absolute;
    display: block;
    left: 5px;
    height: 100%;
    top: 32px;
    background-color: var(--color-blue-500);
    width: 1px;
  }

  li {
    .list-item-wrapper {
      background-color: var(--color-near-white) !important;
      height: 64px;
      padding-left: 1rem;

      .list-text {
        font-family: Avenir;
        text-transform: capitalize;
        font-weight: 800;
      }
    }
  }
}