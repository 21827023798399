.MuiInput-root.select {
  margin-top: 1.25rem !important;
  height: 56px;
  border-radius: 4px;
  border: 1px solid var(--color-light-grey);
  font-family: Avenir;
  padding: 16px;
  color: var(--color-near-black);

  &.Mui-focus {
    border-color: var(--color-near-black);
  }

  .helper {
    .helper-icon {
      height: 10px;
      width: 10px;
      color: var(--color-red-500);
    }

    .text-wrapper {
      padding-top: 1px;
    }
  }

  .Mui-error {
    border-color: var(--color-red-500) !important;
    color: var(--color-red-500) !important;
  }

  .MuiInput-input {
    padding-right: 0 !important;

    &:focus {
      background-color: transparent !important;
    }
  }

  .MuiSelect-icon {
    right: 1.5rem;
  }
}