
#items-selection {
  .colored-title {
    min-height: 100px;
    @media (min-height: 800px) {
      min-height: 150px;
    }
  
    @media (min-height: 900px) {
      min-height: 200px;
    }
    background-color: var(--color-blue-900);
  }

  .blank-footer {
    flex: 2;
  }

  h1 {
    color: white;
    font-size: 36px;
    font-weight: 900;
  }

  h3 {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-light-grey);
    text-transform: none;
  } 
  .button-wrapper {
    height: 70px;
    position: fixed;
    bottom: 15px;
  }

  .reset-polygon-button {
    position: absolute;
    top: 25px;
    left: 25px;
    z-index: 2000;
    border-radius: 20px;
    background-color: var(--color-carbone);
    svg {
      color: white;
    }
  }
}