#search-areas {
  height: 56px;
  border-radius: 4px;
  background-color: white;

  .MuiInput-root {
    padding: 15px;
    font-family: Avenir;
    font-size: 12px;
  }

  .MuiInput-input {
    padding: 0 10px;
  }
}