#create-button {
  position: absolute;
  top: 20px; 
  left: 20px;

  .MuiButton-root {
    justify-content: space-evenly;
    font-family: Avenir;
    text-transform: none;
    color: var(--color-carbone);
    font-size: 14px;
    background-color: white;
    height: 56px;
    width: 170px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.06);
  }

  .title {
    padding-top: 3px;
  }

}

.create-context-menu {
  .MuiPaper-root {
    margin-top: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.06);
    width: 400px;
    max-width: 20vw;
  }

  ul {
    border-radius: 4px;
    background-color: var(--color-white);

    li {
      font-size: 14px;
      font-family: Avenir;
    }
  }
}