.simple-search-bar {
  .MuiInput-root.MuiInputBase-root {
    border-radius: 4px;
    height: 64px;
    font-family: Avenir;
    font-size: 12px;
    background-color: var(--color-near-white);
    margin-top: 0;
    padding: 15px;
  }

  .MuiInput-input {
    padding: 0 10px;
  }
}