#instruction-name-choice {
  .colored-title {
    flex: 3;

    @media (min-height: 800px) {
      .title-wrapper {
        margin-bottom: 4rem;
      }
    }
  
    @media (min-height: 900px) {
      .title-wrapper {
        margin-bottom: 8rem;
      }
    }
  }

  .input-wrapper {
    height: 160px;
  }
}