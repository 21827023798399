.tabs-menu {
  min-height: 55px !important;

  .MuiTabs-scroller {
    border-bottom: solid 1px var(--color-light-grey);
  }

  .MuiTabs-indicator {
    background-color: var(--color-carbone) !important;
  }

  .tabs-menu-item {
    text-transform: none !important;
    flex: 1;
    color: var(--color-medium-grey) !important;
    font-family: Avenir !important;
    font-size: 12px;
    padding-top: 1.25rem;

    &.Mui-selected {
      color: var(--color-carbone) !important;
    }
  }
}