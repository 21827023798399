.popup-wrapper {
  .popup {
    padding: .3rem;
    min-width: 10rem;
    border-radius: 4px;
    background-color: rgba(2, 46, 79, 0.75);

    .MuiListItemButton-root {
      padding-left: .5rem;
    }

    .icon {
      height: 10px;
      min-width: 30px;
      color: #404040;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: .5rem;
    }

    .label {
      font-family: Avenir !important;
      font-size: 12px;
      font-weight: 400;
      color: white;
    }
  }

  .mapboxgl-popup-content {
    background: transparent;
    box-shadow: none;
  }
}