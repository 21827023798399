.instruction-parameters {
  background-color: var(--color-near-white);
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .content-wrapper {
    height: 80%;
    width: 80%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
  }
  
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .title {
      font-weight: 800;
      font-size: 32px;
    }
  }

  .return-button {
    text-transform: none;
    color: black;
    border: solid 1px var(--color-light-grey);
  }

  .tab {
    background-color: var(--color-near-white);
    border-radius: 8px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    text-transform: none;
    font-family: Avenir;
    font-weight: 900;
    font-size: 15px;

    .icon-wrapper {
      margin-bottom: 1rem;
      height: 24px;
      width: 24px;
    }

    .title {
      color: var(--color-blue-500);
    }

    &:not(:last-child) {
      margin-right: 1.5rem;
    }

    &.selected {
      background-color: var(--color-blue-500);
  
      .title {
        color: white
      }
    }
  }

  .content {
    flex: 1;
  }
}