#map-menu {
  position: absolute;
  top: 40px;
  right: 0;
  width: 56px;
  transition: 500ms;

  .icon-wrapper {
    background-color: rgba(39, 39, 39, 0.75);
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
      cursor: pointer;
    }
  }

  &.display-menu {
    right: 320px;
  }
  .navigation-control {
    position: relative !important;
    padding-bottom: 0.2rem;
    .mapboxgl-ctrl-group {
      background: rgba(39, 39, 39, 0.75);
      box-shadow: none !important;
      width: 56px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 0;
      button + button {
        border-top: none;
      }
      > button span {
        color: white;
      }
      
      button.mapboxgl-ctrl-zoom-in, button.mapboxgl-ctrl-zoom-out {        
        height: 56px;
        width: 56px;
      }
      button.mapboxgl-ctrl-compass {
        height: 50px;
        width: 56px;
      }
    }
    .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
      height: 35px;
      background-image: url('../../../assets/icons/plus_light.svg') !important;
    }
    .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
      height: 35px;
      background-image: url('../../../assets/icons/minus.svg') !important;
    }
    .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
      height: 30px;
      background-image: url('../../../assets/icons/compass.svg') !important;
    }
  }
}

.toolbar-menu {
  background-color: white;
  position: absolute;
  transition: 500ms;
  height: 100%;
  width: 320px;
  top: 0;
  right: -320px;
  padding: 20px;

  &.display {
    right: 0;
  }

  .MuiCheckbox-root.Mui-checked {
    color: var(--color-blue-500);
  }

  .title {
    font-size: 12px;
    font-family: Avenir;
    font-weight: 400;
  }
}
