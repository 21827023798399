body {
  background-color: var(--color-white) !important;
  color: #404040 !important;
  font-family: Avenir !important;
  height: 100vh;
  overflow: hidden;

  button:focus {
    outline: none !important;
  }

  h1 {
    font-weight: bold;
    font-size: 16px;
  }

  h3 {
    text-transform: uppercase;
    color: #D7D7D7;
    font-weight: bold;
    font-size: 10px;
  }

  .app-container {
    padding: var(--topbar-height) 0 0 0;
    height: 100vh;
    overflow: hidden;
  }

  .elevation-2 {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
  }
}
