.capture-click {
  cursor: pointer;

  .input {
    margin-top: 1.25rem;
    border-radius: 4px;
    border: 1px solid var(--color-light-grey);
    height: 56px;
    font-family: Avenir;
    padding-left: 16px;
    padding-right: 1.5rem;
    cursor: pointer;
  
    &.Mui-focus {
      border-color: var(--color-near-black);
    }

    button {
      padding: 0;
    }

    input {
      cursor: pointer;

      &.Mui-disabled {
        cursor: default;
      }
    }
  }

  &.no-label {
    margin: 0 !important;
    .input {
      margin-top: 0;
    }
  }

  .Mui-error {
    border-color: var(--color-red-500) !important;
    color: var(--color-red-500) !important;
  }

  .Mui-focus {
    color: var(--color-black) !important;
  }
}