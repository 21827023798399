#areas-tab {
  height: calc(100vh - 168px);
  background-color: var(--color-near-white);

  .button-wrapper {
    .MuiButton-root {
      height: 56px;
      text-transform: none;
      background-color: var(--color-blue-500);
      font-size: 12px;
      font-family: Avenir;
      padding: 20px 60px;
    }

    .MuiButton-startIcon {
      margin-bottom: 2px;
    }
  }
}