
#filter-menu {
  background-color: var(--color-near-black);
  position: absolute;
  transition: 500ms;
  height: 100%;
  width: 500px;
  top: 0;
  left: -500px;
  padding: 40px;
  z-index: 1;
  color: white;
  font-size: 12px;

  &.display {
    left: 0;
  }

  .MuiFormControl-root {
    transition: 0;

    .MuiCheckbox-root.Mui-checked {
      color: var(--color-blue-500);
    }
  
    .title {
      font-family: Avenir;
      font-weight: 400;
    }
  }

}
