#instruction-type-choice {
  .colored-title {
    flex: 3;

    .title-wrapper {
      margin-bottom: 10rem;
    }

    .choice-button {
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-position: center;
      height: 160px;
      width: 160px;

      &.s6 {
        background: url('../../../../assets/icons/S6Card.svg');
      }
      &.s9 {
        background: url('../../../../assets/icons/S9Card.svg');
      }
      &.s11 {
        background: url('../../../../assets/icons/S11Card.svg');
      }
    }
  }
}