.panel {
  width: 100%;
  background-color: var(--color-white);
  height: 100%;

  .button-wrapper {
    width: 100%;
    margin-bottom: 1rem;
    position: absolute;
    bottom: 0;
    z-index: 3000;
  }

  .content-wrapper {
    flex: 1;
    height: calc(100% - 70px);
  }
}