#selection-fulfilled {
  &.blue-background {
    background-color: var(--color-blue-900);
  }

  .check-icon {
    height: 96px;
    width: 96px;
    color: white;
    font-size: 96px !important;
  }

  h2 {
    color: white;
    font-size: 24px;
  }
}