.context-menu {
  ul {
    border-radius: 4px;
    background-color: var(--color-white);
  
    li {
      font-family: Avenir;
      font-weight: 400;
      font-size: 12px;
    }
  }
}