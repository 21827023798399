#admin-home {
  >.MuiTabs-root {
    background-color: var(--color-carbone);
    color: white;

    .close-icon {
      display: none;
    }

    .MuiTabs-indicator {
      height: 0;
    }

    .Mui-selected {
      background-color: var(--color-carbone-500);
    }

    .tab {
      cursor: pointer;
  
      &:hover, &.Mui-selected {
        .close-icon {
          display: inline-block;
          height: 15px;
          width: 15px;
          color: var(--color-medium-grey)
        }
      }
    }

    .tab-title {
      font-family: Avenir;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .MuiTabPanel-root {
    padding: 0;
    height: 100%;
  }
}