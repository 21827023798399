.text-input {
  .MuiInputLabel-root {
    font-family: Avenir;
    
    +.light-input {
      color: white;
    }

    +.filled-input {
      color: var(--color-medium-grey);
    }

    +.outline-input {
      color: var(--color-carbone);
      font-weight: 500;
    }
  }

  .MuiInput-root {
    margin: 1.5rem 0 !important;
    border-radius: 4px;
    padding: 0 1rem;
    height: 56px;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 400;
    
    &.light-input {   
      background-color: white;
      color: black;
    }

    &.filled-input {   
      background-color: var(--color-near-white);
      color: black;
    }

    &.outline-input {   
      background-color: white;
      color: var(--color-carbone);
      border: 1px solid var(--color-light-grey);
      font-size: 16px;
    }
  }
}