.edit-items-list {
  height: 100%;

  .items-wrapper {
    height: calc(100vh - 370px);
    @media (min-height: 800px) {
      height: calc(100vh - 420px);
    }

    @media (min-height: 900px) {
      height: calc(100vh - 470px);
    }
    max-width: 470px;
    overflow: auto;
  }

  .error-wrapper {
    flex: 3;
    max-width: '470px';
    flex-direction: column;

    .warning-icon {
      margin: 1rem;
      height: 100px;
      width: auto;
    }

    .warning-message {
      text-align: center;
    }
  }
}