.autocomplete {
  .input {
    margin-top: 1.25rem;
    margin-right: 1.5rem;
    border-radius: 4px;
    border: 1px solid var(--color-light-grey);
    height: 56px;
    font-family: Avenir;
    padding-left: 16px;
    padding-right: 1.65rem;
  
    &.Mui-focus {
      border-color: var(--color-near-black);
    }
    .MuiInput-input {
      padding: 4px 20px 4px 0px !important;
    }
  }
  
  .helper {
    .helper-icon {
      height: 10px;
      width: 10px;
      color: var(--color-red-500);
    }

    .text-wrapper {
      padding-top: 1px;
    }
  }

  .Mui-error {
    border-color: var(--color-red-500) !important;
    color: var(--color-red-500) !important;
  }

  .Mui-focus {
    color: var(--color-black) !important;
  }
  
  .MuiSelect-icon {
    right: 1.5rem;
  }

  .MuiAutocomplete-endAdornment {
    right: 24px
  }
}