.objects-list {
  width: 100%;
  flex: 1;
  background-color: transparent;
  overflow: auto;
  margin: 1rem 0;

  ul {
    li {
      padding: .25rem 0;
  
      .list-item-wrapper {
        background-color: var(--color-near-white) !important;
        height: 64px;
        padding-left: 1.5rem;
  
        .list-text {
          font-family: Avenir;
          font-weight: 500;
        }
      }
    }
  }
}
