#subnet-validation-modal {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  .MuiPaper-root {
    height: 500px;
    width: 600px;
    padding: 5rem;
  }
  
  h1 {
    font-family: Avenir;
    font-size: 20px;
    font-weight: 500;
  }
}