#users-attributions {
  overflow: auto;
  height: 44vh;

  .MuiInputLabel-root {
    background: none;
  }

  .MuiInputLabel-root, .MuiButtonBase-root, input {
    font-family: Avenir;
    color: black;
  }
}