.details-title {
  .title {
    margin-right: 10px;
    padding-top: 3px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .MuiChip-root {
    color: white;
    height: 20px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 10px;
    
    &.deleted {
      background-color: var(--color-red-500);
    }

    &.conflict {
      background-color: var(--color-warning-900);
    }
  }
}