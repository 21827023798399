#hover-popup {
  .popup-wrapper {
    margin: 10px 0;
    
    .popup {
      padding: .75rem;
      min-width: 10rem;
      border-radius: 4px;
      background-color: rgba(2, 46, 79, 0.75);
  
      .icon {
        height: 25px;
      }

      .title {
        font-size: 16px;
        font-weight: 800;
        color: white;
      }

      .list {
        li {
          padding-left: 0;
          padding-right: 0;
        }

        .value {
          text-align: right;
        }
      }
    }
  
    .mapboxgl-popup-content {
      background: transparent;
      box-shadow: none;
      margin-bottom: 1rem;
    }
  }
}