.attributes-container {
  padding: 0 1.5rem;

  .list {
    li {
      padding-left: 0;
      padding-right: 0;
    }

    .key {
      color: var(--color-grey-500);
      font-size: 12px;
      font-weight: 400;
    }
    
    .value {
      font-weight: 700;
      font-size: 12px;
      color: var(--color-grey-800);
    }

    .nested-array-divider {
      border-style: dashed;
    }
  }

  .last-modification {
    text-transform: none;
    color: var(--color-grey-300);
    font-size: 12px;
    font-weight: 400;
  }

  .modify-button.MuiIconButton-root {
    padding: 0;
  }
}