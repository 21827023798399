.autocomplete-subareas {
  .input {
    border-radius: 4px;
    border: 1px solid var(--color-blue-500);
    height: 64px;
    font-family: Avenir;
    font-weight: 400;
    font-size: 12px;
  }

  .MuiCircularProgress-root {
    margin-right: 24px;
  }

  .start-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .start-icon {
      height: 23px;
      width: 23px;
      margin: 0 16px;
    }
  }

  +.MuiAutocomplete-popper {
    max-height: 50px;
  
    .MuiAutocomplete-noOptions, .MuiAutocomplete-option, .MuiAutocomplete-loading {
      font-family: Avenir;
      font-weight: 400;
      font-size: 12px;
    }

    .MuiAutocomplete-listbox {
      max-height: 200px;
    }
  }
}
