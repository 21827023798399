.initials-wrapper {
  border-radius: 20px;
  height: 32px;
  width: 32px;
  background-color: var(--color-lighter-grey);

  .initials {
    text-align: center;
    font-family: Avenir;
    font-size: 8px;
    font-weight: 900;
    color: var(--color-medium-grey)
  }
}