#feature-click-popup {
  &:hover {
    cursor: pointer;
  }

  .popup-wrapper {
    margin: 10px 0;
    
    .popup {
      min-width: 25rem;
      padding: .75rem;
      border-radius: 12px;
      background-color: rgba(36, 40, 48, 0.8);

      >.title {
        font-size: 16px;
        font-weight: 900;
        color: white;
      }
      
      .scroll-list { 
        overflow: auto;
        max-height: 25rem;
      }

      .content-wrapper {
        flex: 1;
      }

      .object-wrapper {
        background-color: rgba(36, 40, 48, 0.75);
        border-radius: 4px;
        height: 4rem;
        align-items: center;
      }
  
      .icon {
        height: 28px;
      }

      .title-wrapper {
        height: 35px;
        justify-content: center;

        .title {
          font-size: 16px;
          font-weight: 800;
          color: white;
        }
  
        .sub-title {
          font-size: 12px;
          font-weight: 400;
          color: var(--color-medium-grey);
        }
      }

      .status-wrapper {
        margin-right: .25rem;

        .status-title {
          font-family: Avenir;
          font-size: 11px;
          color: white;
          margin-right: .5rem;

          &.color-20 {
            color: var(--color-light-yellow);
          }
          &.color-30 {
            color: var(--color-blue-500);
          }
          &.color-40 {
            color: var(--color-green-900);
          }
        }
        
        .status-icon {
          height: 24px;
        }
      }
    }
  
    .mapboxgl-popup-content {
      background: transparent;
      box-shadow: none;
      margin-bottom: 1rem;
    }
  }
}