.date-picker {
  .MuiFormControl-root {
    width: 100%;
  }

  .input {
    margin-top: 1.25rem;
    border-radius: 4px;
    border: 1px solid var(--color-light-grey);
    height: 56px;
    font-family: Avenir;
    padding-left: 16px;
    padding-right: 1.5rem;
  
    &.Mui-focus {
      border-color: var(--color-near-black);
    }

    button {
      padding: 0;
    }
  }

  .MuiFormControlLabel-label {
    font-family: Avenir;
  }
  
  .MuiCheckbox-colorPrimary, .Mui-checked {
    color: var(--color-blue-500)
  }
}